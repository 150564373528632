import { Card, CardImg } from 'react-bootstrap'

import { Link } from 'react-router-dom';

import "./youtubecard.css"



function YouTubeCard()
{
    return(
        <Link to="https://youtube.com/johnkaraffa" className="link-text">
            <Card className="youtubecard">
                <CardImg className="youtubecardlogo" src="../images/youtubelogo.png"/>
            <Card.Body>
                <Card.Text>John's YouTube </Card.Text>
                <Card.Text> </Card.Text>
            </Card.Body>
            </Card>
        </Link>
    )
}

export default YouTubeCard;