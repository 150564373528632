import { Card, CardImg } from 'react-bootstrap'

import { Link } from 'react-router-dom';

import "./chatcard.css"



function ChatCard()
{
    return(
        <Link to="/chat" className="link-text">
            <Card className="chatcard">
                <CardImg className="chatcardlogo" src="../images/chatlogo.png"/>
            <Card.Body>
                <Card.Text>John's Digital Twin </Card.Text>
                <Card.Text> </Card.Text>
            </Card.Body>
            </Card>
        </Link>
    )
}

export default ChatCard;