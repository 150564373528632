import PropTypes from "prop-types"
import React from "react"

import "./footer.css"

function Footer({siteTitle})
{
    return(
    <footer className = "footer">
      <div>
        <p></p>
        <p className = "footer">
          {siteTitle} {` `} © {new Date().getFullYear()} {'\n'}
        </p>
        <p>Built with love</p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
