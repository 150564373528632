import React from 'react'

import {Container} from 'react-bootstrap'

import JohnKaraffaColorLogo from '../components/JohnKaraffaColorLogo'
import SoundCloudPlayer from '../components/soundcloudplayer'
import Footer from '../components/footer'


export default function SoundCloudPage()
{
  return (
    <Container>
          <JohnKaraffaColorLogo/>
          <SoundCloudPlayer/>
          <Footer siteTitle="John Karaffa"/>
    </Container>
  );
} 