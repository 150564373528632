import React from 'react'

import {Container} from 'react-bootstrap'

import JohnKaraffaColorLogo from '../components/JohnKaraffaColorLogo'
import Footer from '../components/footer'
import SlideCarousel from '../components/slidecarousel'

export default function HomePage()
{
  return (
    <Container>
        <JohnKaraffaColorLogo/>
        <SlideCarousel/>
        <Footer siteTitle="John Karaffa"/>
    </Container>
  );
} 