import { Carousel } from 'react-bootstrap'

import "./slidecarousel.css"

import SoundCloudCard from './soundcloudcard';
import ChatCard from './chatcard';
import YouTubeCard from './youtubecard';

function SlideCarousel()
{
    return(
        <Carousel interval={3000} slide={true} className="slidefadecarousel">
            <Carousel.Item>
                <SoundCloudCard/>
            </Carousel.Item>
            <Carousel.Item>
                <YouTubeCard/>
            </Carousel.Item>
            <Carousel.Item>
                <ChatCard/>
            </Carousel.Item>
        </Carousel>
    )
}

export default SlideCarousel;