import { Form } from 'react-bootstrap'

import "./chatinputfield.css"



function ChatInputField({getChatInputFieldValue})
{
    function handleOnChange(e)
    {
        getChatInputFieldValue(e.target.value);
    }

    return (
        <>
          <Form.Control className="chatinputfield" type="text" placeholder="Enter a question to chat with me" id="chatinputfield" aria-describedby="Chat Input Field" onChange={(e) => handleOnChange(e)} />
        </>
      );
}

export default ChatInputField;