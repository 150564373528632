
import "./soundcloudplayer.css"

function SoundCloudPlayer()
{
    return(
        <iframe title="John Karaffa on SoundCloud" width="100%" height="450" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1082416465&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
    )
}

export default SoundCloudPlayer;