import { useState } from 'react';

import "./chatcontrol.css"

import ChatResponseForm from './chatresponseform';
import ChatInputField from './chatinputfield';

import Button from 'react-bootstrap/Button';

function ChatControl()
{   

    //API endpoints
    const chatAPIBase = "https://fwgq6l2mth.execute-api.us-east-1.amazonaws.com/Production";
    const chatApiInitializeChat = chatAPIBase + "/create_empty_thread";
    const chatApiAddMessageToChat = chatAPIBase + "/add_new_user_message_to_thread";
    const chatApiProcessChatMessage = chatAPIBase + "/send_user_message_to_openai";
    const chatApiGetChatMessageResponse = chatAPIBase + "/list_thread_messages";
    const chatApiEndChat = chatAPIBase + "/delete_thread";

    //OpenAI AssistantID
    const assistantID = "asst_iNHdAXx7PLWc37dthSvPY0w7";

    //Display state variables
    const [chatinputField, setChatInputField] = useState("");
    const [answertodisplay, setAnswerToDisplay] = useState("");


    //Chat state variables
    const [ischatinitiated, setIsChatInitiated] = useState(false);
    
    //ThreadID state variable
    const [threadID, setThreadID] = useState("");

    function getChatInputFieldValue(chatInputFieldValueToSet )
    {
        setChatInputField(chatInputFieldValueToSet)
    }

    const initializeChat = async () =>
    {
        try {

            console.log("initializeChat");

            //Create a new thread in OpenAI
            const response = await fetch(chatApiInitializeChat, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body:JSON.stringify({})
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            //Get the api response
            const data = await response.json();

            //Set that the chat is initiated so it doesn't create another new thread
            setIsChatInitiated(true);

            //Log response to console
            console.log(data);

            //Return ThreadID
            return data;

          } catch (error) {
            console.error('Error:', error);
        }
    }

    const addMessageToChat = async(inputThreadID) =>
    {
        try {

            console.log("addMessageToChat");

            //Add user message to the thread
            const response = await fetch(chatApiAddMessageToChat, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body:JSON.stringify({userMessage:chatinputField, threadID: inputThreadID})
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            //Get the api response
            const data = await response.json();

            //Log response to console
            console.log(data);

            //Return data
            return data;

          } catch (error) {
            console.error('Error:', error);
        }
    }

    const processChatMessage = async (inputAssistantID, inputThreadID) =>
    {
        try {

            console.log("processChatMessage");
            
            //Add process the user message
            const response = await fetch(chatApiProcessChatMessage, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body:JSON.stringify({openaiAssistantID:inputAssistantID, threadID: inputThreadID})
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            //Get the api response
            const data = await response.json();
            
            //Log response to console
            console.log(data);

            //Return data
            return data;

          } catch (error) {
            console.error('Error:', error);
        }
    }

    const getChatMessageResponse = async (inputThreadID, inputOnlyReturnAssistantMessages) =>
    {
        try {

            console.log("getChatMessageResponse");

            //Add process the user message
            const response = await fetch(chatApiGetChatMessageResponse, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body:JSON.stringify({threadID: inputThreadID, onlyReturnAssistantMessages: inputOnlyReturnAssistantMessages})
            });


            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            //Get the api response
            const data = await response.json();

            //Log response to console
            console.log(data);

            //Return data
            return data;

          } catch (error) {
            console.error('Error:', error);
        }
    }

    function formatChatMessageResponse(chatResponseMessage)
    {
        console.log("formatChatMessageResponse");

        //Get the message to display
        const messageToDisplay = chatResponseMessage.threadMessages[0].content[0].text.value;

        //Set the state variable
        //setAnswerToDisplay(messageToDisplay);

        return messageToDisplay;
    }

    const endChat = async (inputThreadID) =>
    {
        try {

            console.log("endChat");

            //Create a new thread in OpenAI
            const response = await fetch(chatApiEndChat, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body:JSON.stringify({threadID: inputThreadID})
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            //Get the api response
            const data = await response.json();

            //Set that the chat is not intiated so it does create another new thread
            setIsChatInitiated(false);

            //Log response to console
            console.log(data);

            //Return data
            return data;


          } catch (error) {
            console.error('Error:', error);
        }

        //Set that the chat is initiated so it doesn't create another new thread
        setIsChatInitiated(false);
    }

    const chatButtonClicked = async (inputQuestion) =>
    {   

      if(ischatinitiated === false)
      {

        setAnswerToDisplay("Preparing Message...");

        const initializeChatResponse = await initializeChat();

        const localThreadID = initializeChatResponse.threadID;
        
        //Store the returned threadID as a state variable
        setThreadID(localThreadID);
        
        const localAddMessageToChatResponse = await addMessageToChat(localThreadID);

        setAnswerToDisplay("Talking To John Karaffa's Digital Twin...");

        const localProcessChatResponse = await processChatMessage(assistantID, localThreadID);

        setAnswerToDisplay("Listening To John Karaffa's Digital Twin...");

        const localChatMessageResponse = await getChatMessageResponse(localThreadID, true);

        const chatAnswer = formatChatMessageResponse(localChatMessageResponse);

        setAnswerToDisplay(chatAnswer);

      }
      else
      {
          setAnswerToDisplay("Preparing Message...");

          const localThreadID = threadID;

          const localAddMessageToChatResponse = await addMessageToChat(localThreadID);

          setAnswerToDisplay("Talking To John Karaffa's Digital Twin...");

          const localProcessChatResponse = await processChatMessage(assistantID, localThreadID);

          setAnswerToDisplay("Listening To John Karaffa's Digital Twin...");

          const localChatMessageResponse = await getChatMessageResponse(localThreadID, true);

          const chatAnswer = formatChatMessageResponse(localChatMessageResponse);

          setAnswerToDisplay(chatAnswer);
      }
    }
    return (
        <>
            <ChatInputField getChatInputFieldValue={getChatInputFieldValue}/>
            <br></br>

            <Button variant="primary" size="lg" onClick={chatButtonClicked}>Chat</Button>

            <br></br>
            <br></br>
            <ChatResponseForm answerToDisplay={answertodisplay}/>
        </>
    );
}

export default ChatControl;