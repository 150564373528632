import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './JohnKaraffaColorLogo.css'

const ImagePath = '../images/JohnKaraffaColorLogo.svg'

function JohnKaraffaColorLogo()
{
    return(
        <Link to="/">
            <Image src={ImagePath} className="johnkaraffacolorlogo" />
        </Link>
    ) 
}

export default JohnKaraffaColorLogo;