import { Card, CardImg } from 'react-bootstrap'

import { Link } from 'react-router-dom';

import "./soundcloudcard.css"

function SoundCloudCard()
{
    return(
        <Link to="/soundcloud" className="link-text">
            <Card className="soundcloudcard" onClick={console.log("SoundCloudCard Clicked")}>
                <CardImg className="soundcloudcardlogo" src="../images/soundcloudlogo.png"/>
            <Card.Body>
                <Card.Text>Music by John Karaffa </Card.Text>
                <Card.Text> </Card.Text>
            </Card.Body>
            </Card>
        </Link>
    )
}

export default SoundCloudCard;