import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import './App.css';

import HomePage from "./pages/home"
import SoundCloudPage from "./pages/soundcloud"
import ChatPage from "./pages/chat"

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/soundcloud" element={<SoundCloudPage />}/>
            <Route path="/chat" element={<ChatPage />}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}