import React from 'react'

import {Container} from 'react-bootstrap'

import JohnKaraffaColorLogo from '../components/JohnKaraffaColorLogo'
import Footer from '../components/footer'

import ChatControl from '../components/chatcontrol'


export default function ChatPage()
{
  return (
    <Container>
          <JohnKaraffaColorLogo/>
          <ChatControl/>
          <Footer siteTitle="John Karaffa"/>
    </Container>
  );
}