import { Form } from 'react-bootstrap'

import "./chatresponseform.css"



function ChatResponseForm({questionToDisplay, answerToDisplay})
{
    return(
        <Form>
            <Form.Control as="textarea" rows={20} value={answerToDisplay} className="chatresponseform"  aria-label="Answer" readOnly/>
        </Form>
    )
}

export default ChatResponseForm;